import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "output",
    "form",
    "template",
    "container",
    "counter",
    "footer",
    "cause",
  ];
  static values = {
    counterVal: { type: Number, default: 0 }
  }

  initialize() {
    this.removedCauseIds = [];
  }
  validateModalCauseCount(e) {
    if (
      this.element.dataset.validateCauseCount == "1" &&
      this.containerTarget.querySelectorAll(".selected-cause:not(.d-none)").length >=
        3
    ) {
      e.stopPropagation();
      e.preventDefault();
      this.outputTarget.style.display = "block";
      this.footerTarget.style.height = "154px";
      return;
    }
    this.modalController.show(e);
  }
  submitCausesForm(e) {
    e.preventDefault();
    const data = new URLSearchParams(new FormData(this.formTarget));
    fetch(this.formTarget.getAttribute("action"), {
      method: "post",
      body: data,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    }).then(() => {
      this.unsavedFormController.allowFormSubmission(e);
    });
  }
  validate(e) {
    try {
      if (e.target.tagName.toLowerCase() == "a") return;
      let causeId = e.currentTarget.dataset.causeid;
      // Check if there is already a cause added
      let existingCause = this.containerTarget.querySelector(
        `input.cause-id[value="${causeId}"]`,
      );
      if (
        existingCause &&
        (existingCause.parentNode.parentNode.classList.contains("d-flex") ||
          this.element.dataset.validateCauseCount != "1" ||
          (this.element.dataset.validateCauseCount == "1" &&
            this.containerTarget.querySelectorAll(".selected-cause.d-flex")
              .length < 3))
      ) {
        // e.currentTarget.classList.toggle('active');
        this.outputTarget.style.display = "none";
        this.footerTarget.style.height = "91px";

        // set as destroy if there is
        let causeParent = existingCause.parentNode.parentNode;
        causeParent.classList.toggle("d-none");
        causeParent.classList.toggle("d-flex");
        causeParent.querySelectorAll("input.remove-cause").forEach(destroyInput => {
          const value = destroyInput.value;
          const newValue = value == "true" ? "false" : "true";
          destroyInput.value = newValue;
        });
        this.displaySelectedCauseOverlay(e);
      } else if (
        this.element.dataset.validateCauseCount == "1" &&
        this.containerTarget.querySelectorAll(".selected-cause.d-flex").length >=
          3
      ) {
        e.stopPropagation();
        e.preventDefault();
        this.outputTarget.style.display = "block";
        this.footerTarget.style.height = "154px";
        // extend height of footer
      } else {
        // e.currentTarget.classList.toggle('active');
        this.displaySelectedCauseOverlay(e);
        this.outputTarget.style.display = "none";
        this.footerTarget.style.height = "91px";
        // remove height of footer
        // Add new record if not
        let new_index = new Date().getTime();
        let content = this.templateTarget.innerHTML.replace(
          /NEW_RECORD/g,
          new_index,
        );
        this.containerTarget.insertAdjacentHTML("beforeend", content);
        let cause = this.containerTarget.querySelector(
          "#cause_uncheck_" + new_index,
        );
        cause.querySelector(".causeName").innerHTML =
          `${e.currentTarget.dataset.cause} by `;
        cause.querySelector(".organizationName").innerHTML =
          e.currentTarget.dataset.organization;
        cause.querySelectorAll("input.cause-id").forEach((input) => {
          input.value = e.currentTarget.dataset.causeid;
        });
      }
    } finally {
      this.updateSelectedCount();
    }
  }
  uncheckCause(e) {
    this.outputTarget.style.display = "none";
    this.footerTarget.style.height = "91px";
    let causeParent = e.currentTarget.parentNode.parentNode;
    let causeId = causeParent.querySelector("input.cause-id").value;
    let causeCard = document.getElementById(`cause_${causeId}`);
    if (causeCard) {
      let selectedOverlay = causeCard.querySelector(".selected-overlay");
      selectedOverlay.classList.toggle("d-none");
      causeCard.classList.toggle("active");
      causeCard.classList.toggle("cursor-pointer");
      causeCard.classList.toggle("pointer-events-none");
      causeCard.classList.toggle("cause-element-alt-selected");
    }
    causeParent.classList.toggle("d-none");
    causeParent.classList.toggle("d-flex");
    let removeCauses = causeParent.querySelectorAll("input.remove-cause");
    removeCauses.forEach(removeCause => {
      removeCause.value = removeCause.value == "true" ? "false" : "true";
    })
    this.updateSelectedCount();
  }
  removeUnactiveCauseCards() {}

  updateSelectedCount() {
    const selectedCount = document.querySelectorAll('.selected-causes-container .selected-cause:not(.d-none)').length;
    console.log(selectedCount);
    this.counterVal = selectedCount;
    this.counterTarget.innerText = this.counterVal;
  }

  displaySelectedCauseOverlay(e) {
    let overlay = e.currentTarget.querySelector(".selected-overlay");
    e.currentTarget.classList.toggle("cause-element-alt-selected");
    e.currentTarget.classList.toggle("cursor-pointer");
    e.currentTarget.classList.toggle("pointer-events-none");
    // e.currentTarget.parentNode.classList.toggle("cause-element-alt-selected");
    overlay.classList.toggle("d-none");
  }
  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "modal",
    );
  }
  get unsavedFormController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "unsaved-changes",
    );
  }
}
